.listView {
  padding-bottom: 10rem;
}

.listView table {
  border-collapse: collapse;
  width: 100%;
  color: #555;
}

.listView::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.listView th {
  padding: 0 2px 0 8px;
  height: 35px; /* assigning some height to make it work with min-height otherwise min-height won't work*/
  min-height: 35px;
  background-color: #eaeff5;
}
/* 
.listView th:not(.extraRow) {
  min-width: 150px;
} */

.listView th,
.listView td {
  border: 1px solid #d0dbd8;
  max-width: 200px;
  min-width: 200px;
  overflow: hidden;
}

.listView th:nth-child(2),
.listView td:nth-child(2) {
  border-left-color: transparent;
}

.listView th:last-child,
.listView td:last-child {
  border-right-color: transparent;
}

.listView .extraRow {
  padding: 0 6px;
  min-width: 50px;
  background-color: #eaeff5;
}

.extraRow button {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.listView tr:hover .btnTrash {
  opacity: 1;
}

.tableCell button {
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.tableCell:hover button {
  visibility: visible;
  opacity: 1;
}

.stickyCell {
  outline: 1px solid #e0e0e0;
}

.descriptionCell {
  max-height: 150px;
  overflow: auto;
}

.descriptionCell .ql-editor {
  border: none;
  background: transparent;
}

.descriptionCell div {
  border: none !important;
}

.createTaskModalInput::placeholder {
  color: #ccc;
}
