.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .beamer_defaultBeamerSelector {
  display: none;
} */

.section-loader span {
  width: 15px;
  height: 15px;
  animation: scale-up 1s ease-in-out infinite;
}

.section-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.section-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

.react-datepicker-popper {
  /* overwriting date-picker z-index */
  z-index: 999 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes scale-up {
  50% {
    transform: scale(0.75, 2);
  }
}

::-ms-reveal {
  display: none !important; /*hiding edge's default eye icon from the password field*/
}

@media print {
  .invoice-print {
    box-shadow: none !important;
    border: none !important;
  }
}

/* ============ CK EDITOR ================ */
.ck-editor__editable {
  height: auto;
  overflow: auto;
  /* --ck-color-focus-border: #1600e5; */
  --ck-focus-ring: 1px solid #1600e5;
}

.ck-sticky-panel__placeholder {
  display: none !important;
}

.ck-sticky-panel__content_sticky {
  position: relative !important;
}

/* ============= Utils Classes =========== */
.show-on-hover-child {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  visibility: hidden;
}

.show-on-hover-parent:hover .show-on-hover-child {
  opacity: 1;
  visibility: visible;
}

input[type='date'].white-icon::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 15px;
  height: 15px;
}

input[type='time'].hide-time-icon::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
/* ========= react grid layout fix ========== */
.react-grid-layout {
  position: relative;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 15px !important;
  height: 15px !important;
  border-right: 6px solid #1600e5 !important;
  border-bottom: 6px solid #1600e5 !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 18px !important;
  right: -5px !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 18px !important;
  left: -5px !important;
}

/* ===== Project calendar view ===== */
.App__project-calendar-view .rbc-header {
  padding: 6px 3px;
  text-transform: uppercase;
  font-weight: 600;
}
.App__project-calendar-view .rbc-button-link {
  font-size: 13px;
}

.ql-editor a {
  cursor: pointer;
}

/* .fc-widget-small {
left: 15px !important;
bottom: 15px !important;
} */
