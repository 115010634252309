.invoice-email {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.invoice-email h2,
.invoice-email h3,
.invoice-email h4 {
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 12px;
}

.invoice-email h2 {
  font-size: 1.35rem;
}

.invoice-email h3 {
  font-size: 1.15rem;
}

.invoice-email h4 {
  font-size: 1rem;
}

.invoice-email p {
  font-size: 15px;
  color: #737373;
  line-height: 1.8;
}

.invoice-email a {
  color: blue;
}

.invoice-email__logo {
  display: inline-block;
  height: 35px;
  margin-bottom: 12px;
}

.invoice-email__container {
  border: 1px solid #d7d7d7;
  padding: 30px;
  border-radius: 4px;
}

.invoice-email__body {
  word-break: break-word;
}

.invoice-email__footer {
  text-align: center;
  margin-top: 3rem;
}

.invoice-email__link {
  padding: 10px 18px;
  background: #1600e5 !important;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  text-decoration: none;
}

.email-link {
  color: '#fff';
}
